<template>
  <div class="privacy-container container p-3 p-md-5">
    <div class="card extra-semi-transparent-card p-4 p-md-5">
      <p class="text-center mb-5">
        <strong>Privacy Policy For COMMUNITY CARE CONNECTION INC.</strong>
      </p>
      <p>
        By accessing the website,&nbsp;you agree to the terms of this policy.
      </p>
      <ol class="decimal_type main_list" style="list-style-type: decimal">
        <li>C3 respects your privacy.</li>
        <li>
          Security is important to us. We strive to use commercially acceptable
          means to protect your information, but we cannot guarantee its
          absolute security.
        </li>
        <li>
          We collect information you give us when you register or use our online
          forms. We track the web pages you visit through the use of cookies.
        </li>
        <li>
          We may use your information to improve the performance and accuracy of
          our website and system. We will only do so if we can preserve the
          confidentiality of user identifiable information.
        </li>
        <li>
          If you choose to post publicly on our blog or otherwise explicitly
          give us permission to share your identifiable information with others,
          this information may become public.
        </li>
        <li>You can opt out of marketing communications we send.</li>
        <li>We will not sell your information to a third party.</li>
        <li>
          We may link to other websites. We cannot vouch for their privacy
          policies.
        </li>
        <li>We will post updates to our privacy policy on our website.</li>
        <li>
          Use of the website is subject to the Terms of Use agreement, which is
          available
          <router-link to="/terms">here</router-link>
          .
        </li>
        <li>
          Please contact us if you would like to learn more about our company
          and our policies.
          <p><strong>&nbsp;</strong></p>
          <p style="padding-left: 0.4in">
            <strong>Information Collection</strong>
          </p>
          <ol class="decimal_type">
            <li>
              <strong
                ><span
                  >Information [COMMUNITY CARE CONNECTION INC.] Collects</span
                ></strong
              ><span
                >. [COMMUNITY CARE CONNECTION] may collect information that
                identifies Users when accessing the Website (collectively,
                &quot;Information&quot;), which includes</span
              >
              <ol type="a" style="list-style-type: lower-alpha">
                <li>
                  <span
                    >usernames, addresses, phone numbers, birth dates, billing
                    and delivery information, email addresses, credit card
                    information, and other financial account information
                    (&quot;Personally Identifiable
                    Information&quot;),&nbsp;</span
                  >
                </li>
                <li>
                  <span
                    >other information Users provide to [COMMUNITY CARE
                    CONNECTION] but that does not identify Users personally,
                    such as records of User visits, and information Users submit
                    when using the Website,&nbsp;</span
                  >
                </li>
                <li><span>information from third parties, and</span></li>
                <li>
                  <span
                    >traffic and usage information generated from
                    User&nbsp;visits to the website, including traffic data and
                    the type of device that Users use to&nbsp;access the
                    Website.</span
                  >
                </li>
              </ol>
            </li>
            <li>
              <strong
                ><span
                  >Tracking Technologies [COMMUNITY CARE CONNECTION] Uses</span
                ></strong
              ><span
                >. [COMMUNITY CARE CONNECTION] may use a variety
                of&nbsp;technologies to collect Information, including cookies,
                flash cookies, general log information, and referral information
                from third party websites.</span
              >
            </li>
          </ol>
          <p><strong>&nbsp;</strong></p>
        </li>
        <li>
          <strong
            ><span
              >How [COMMUNITY CARE CONNECTION] Uses Information</span
            ></strong
          ><span
            >. [COMMUNITY CARE CONNECTION] may use the Information
            its&nbsp;collects&nbsp;to</span
          >
          <ol class="decimal_type">
            <li>
              <span
                >provide, operate, maintain, improve, and promote, the
                Website,</span
              >
            </li>
            <li>
              <span
                >enable Users&nbsp;to access and use the Website, including
                uploading, downloading, collaborating on, and sharing
                content,</span
              >
            </li>
            <li>
              <span
                >process and complete transactions, and send Users&nbsp;related
                information, including purchase confirmations and
                invoices,</span
              >
            </li>
            <li>
              <span
                >send transactional messages, including responding to
                User&nbsp;comments, questions, and requests, providing
                User&nbsp;service and support, and sending Users&nbsp;technical
                notices, updates, security alerts, and support and
                administrative messages,</span
              >
            </li>
            <li>
              <span
                >send promotional communications, such as providing
                Users&nbsp;with information about services, features,
                newsletters, offers, promotions, contests, events, and sending
                updates about User&nbsp;team and chat rooms,</span
              >
            </li>
            <li>
              <span
                >monitor and analyze trends, usage, and activities in connection
                with the Website and for marketing or advertising
                purposes,</span
              >
            </li>
            <li>
              <span
                >investigate and prevent fraudulent transactions, unauthorized
                access to the Website, and other illegal activities,</span
              >
            </li>
            <li>
              <span
                >personalize the Website content, features, or advertisements,
                and</span
              >
            </li>
            <li>
              <span
                >enable Users&nbsp;to share content with other Users&nbsp;they
                designate.</span
              >
            </li>
          </ol>
        </li>
        <li>
          <strong><span>Information Advertising and Sharing</span></strong>
          <ol class="decimal_type">
            <li>
              <strong><span>Advertising and Cookies</span></strong>
              <ol style="list-style-type: lower-alpha">
                <li>
                  <strong
                    ><span
                      >Third party advertiser for Community Care Connection and
                      Cookies</span
                    ></strong
                  ><span
                    >.&nbsp;[COMMUNITY CARE CONNECTION] may permit third party
                    advertising partners to deliver ads to Users and use cookies
                    on the Website.</span
                  >
                </li>
                <li>
                  <strong
                    ><span
                      >Policy Does Not Cover Third party advertisers for
                      Community Care Connection</span
                    ></strong
                  ><span
                    >.&nbsp;This policy does not cover third Community Care
                    Connection advertising partners&apos; use of cookies, which
                    is&nbsp;subject to the third Community Care Connection
                    advertising partners&apos; own privacy policies.</span
                  >
                </li>
                <li>
                  <strong><span>Use of Cookies</span></strong
                  ><span
                    >. [COMMUNITY CARE CONNECTION] may, and may permit third
                    Community Care Connection advertising partners, to use
                    cookies to</span
                  >
                  <ol type="i">
                    <li><span>recognize a User&apos;s computers,</span></li>
                    <li>
                      <span
                        >send online advertisements to Users, and&nbsp;</span
                      >
                    </li>
                    <li>
                      <span
                        >compile Information about a User&apos;s, and
                        other&apos;s, use of its computer.</span
                      >
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <strong><span>Service Providers</span></strong
              ><span
                >. [COMMUNITY CARE CONNECTION] may share Information
                with&nbsp;third party service providers it employs to perform
                functions and provide services for [COMMUNITY CARE CONNECTION]
                and the Website, subject to the terms of this policy, and only
                to the extent necessary</span
              >
              <ol type="a" style="list-style-type: lower-alpha">
                <li><span>to process payments,</span></li>
                <li>
                  <span
                    >to prevent, detect, and investigate fraud or other
                    prohibited activities,</span
                  >
                </li>
                <li>
                  <span
                    >to facilitate dispute resolution, such as chargebacks or
                    refunds, and</span
                  >
                </li>
                <li>
                  <span
                    >for other purposes associated with the acceptance of credit
                    or debit cards.</span
                  >
                </li>
              </ol>
            </li>
            <li>
              <strong><span>Credit and Debit Card Information</span></strong
              ><span
                >.&nbsp;[COMMUNITY CARE CONNECTION]&nbsp;may share
                User&nbsp;credit or debit card numbers with third party payment
                services providers or card networks, only to the extent
                necessary to monitor card transactions at participating
                merchants and track redemption activity for the purposes of
                providing card-linked services.</span
              >
            </li>
          </ol>
        </li>
        <li>
          <strong><span>User Settings</span></strong
          ><span
            >. [COMMUNITY CARE CONNECTION] shall provide Users&nbsp;the ability
            to exercise certain controls and choices regarding [COMMUNITY CARE
            CONNECTION]&apos;s&nbsp;collection, use, and sharing of
            User&nbsp;information, to the extent required by Law, which might
            include a User&apos;s ability to</span
          >
          <ol class="decimal_type">
            <li>
              <span>correct, update, and delete account information,</span>
            </li>
            <li>
              <span>change choices for subscriptions and newsletters,</span>
            </li>
            <li>
              <span
                >choose whether or not to receive offers from [COMMUNITY CARE
                CONNECTION], and&nbsp;</span
              >
            </li>
            <li>
              <span
                >choose whether the User&nbsp;receives targeted advertising from
                [COMMUNITY CARE CONNECTION]&nbsp;or third party
                advertising&nbsp;partners.</span
              >
            </li>
          </ol>
        </li>
        <li>
          <strong
            ><span>Accessing and Correcting User&nbsp;Information</span></strong
          >
          <ol class="decimal_type">
            <li>
              <strong
                ><span
                  >Opportunity to Correct Contact Information and
                  Preferences</span
                ></strong
              ><span
                >.&nbsp;[COMMUNITY CARE CONNECTION] shall provide Users with the
                opportunity to ensure that contact information and preferences
                are accurate, complete, and up to date, through each User&apos;s
                account, accessible from&nbsp;[WEBSITE LOGIN URL].</span
              >
            </li>
            <li>
              <strong
                ><span
                  >Opportunity to Request Corrections and Deletions</span
                ></strong
              ><span
                >. Subject to paragraph [DISCRETION OVER CORRECTIONS AND
                DELETIONS], [COMMUNITY CARE CONNECTION] shall provide Users with
                the opportunity to request that [COMMUNITY CARE
                CONNECTION]</span
              >
              <ol type="a" style="list-style-type: lower-alpha">
                <li>
                  <span
                    >correct inaccurate Personally Identifiable Information
                    [COMMUNITY CARE CONNECTION]&nbsp;holds, and</span
                  >
                </li>
                <li>
                  <span
                    >delete Information [COMMUNITY CARE CONNECTION] is&nbsp;not
                    required to retain by Law or for legitimate business
                    purposes.</span
                  >
                </li>
              </ol>
            </li>
            <li>
              <strong
                ><span>Discretion Over Corrections and Deletions</span></strong
              ><span
                >.&nbsp;[COMMUNITY CARE CONNECTION] will have the sole
                discretion&nbsp;to adhere to or decline User requests to correct
                or delete Information that are&nbsp;frivolous or vexatious,
                jeopardize the privacy of others, are extremely impractical, or
                not otherwise required by Law.</span
              >
            </li>
          </ol>
        </li>
        <li>
          <strong><span>No Security Guarantees</span></strong
          ><span
            >. [COMMUNITY CARE CONNECTION] does not guarantee against all
            unauthorized disclosure, alteration, or destruction or
            Information.</span
          >
        </li>
        <li>
          <strong><span>Changes to Policy</span></strong>
          <ol class="decimal_type">
            <li>
              <strong><span>Discretion to Make Changes</span></strong
              ><span
                >. [COMMUNITY CARE CONNECTION] may change this policy at its
                discretion.&nbsp;</span
              >
            </li>
            <li>
              <strong><span>Notice of Changes</span></strong
              ><span
                >.&nbsp;[COMMUNITY CARE CONNECTION] shall provide reasonable
                notice of any such changes on the Website.</span
              >
            </li>
            <li>
              <strong><span>Acceptance of</span></strong
              ><span
                >&nbsp;<strong>Changes</strong>.<strong>&nbsp;</strong>Users&apos;
                continued use of the Website after notice of the changes has
                been posted to the Website will be deemed acceptance of the
                changes, if</span
              >
              <ol style="list-style-type: lower-alpha">
                <li>
                  <span
                    >&nbsp;a reasonable time has passed in which Users could
                    review the changes, but</span
                  >
                </li>
                <li>
                  <span
                    >regardless as to whether the User actually did review the
                    changes.</span
                  >
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <strong><span>Links to Other Websites</span></strong
          ><span
            >. [COMMUNITY CARE CONNECTION] will not be responsible for the
            content on or privacy practices of third-party sites linked to from
            the Website.</span
          >
        </li>
        <li>
          <strong><span>International Users</span></strong>
          <ol class="decimal_type">
            <li>
              <strong><span>Compliance with Safe Harbors</span></strong
              ><span
                >.&nbsp;[COMMUNITY CARE CONNECTION] shall&nbsp;comply with the
                Canadian and US Framework for compliance in Canada HIPPA,
                PAPEIDA, FIPPA as well as the U.S. - Swiss Safe Harbor
                Framework, as listed&nbsp;by the U.S Department of Commerce
                regarding the collection, use, and retention of personal data
                from the European Union member countries, and</span
              >
            </li>
            <li>
              <strong><span>Certification</span></strong
              ><span
                >.&nbsp;[COMMUNITY CARE CONNECTION] is&nbsp;certified
                to&nbsp;adhere with&nbsp;the Canadian privacy compliance (
                HIPPA, PAPEIDA FIPPA) Privacy Principles of notice, choice,
                onward transfer, security, data integrity, access, and
                enforcement with respect to the Website.</span
              >
            </li>
            <li>
              <strong><span>Information Regarding Safe Harbors</span></strong
              ><span
                >. [COMMUNITY CARE CONNECTION] shall provide Users information
                about Canadian Privacy policies, and [COMMUNITY CARE
                CONNECTION]&apos;s certification for the Website at&nbsp;</span
              ><a href="https://www.hippa.com">https://www.hippa.com</a>
            </li>
          </ol>
        </li>
        <p>&nbsp;</p>
        <li>
          <strong><span>Contact</span></strong
          ><span
            >. Users may&nbsp;contact [COMMUNITY CARE CONNECTION] at [COMMUNITY
            CARE CONNECTION ADDRESS] or by email at [COMMUNITY CARE CONNECTION
            PRIVACY CONTACT EMAIL].</span
          >
        </li>
        <p>&nbsp;</p>
        <li>
          <strong>Definitions</strong>
          <p>"Effective Date" is defined in the introduction to this policy.</p>
          <p>"Information" is defined in section [INFORMATION COLLECTION].</p>
          <p>"Law" means</p>
          <p>
            (a) any law (including the common law), statute, bylaw, rule,
            regulation, order, ordinance, treaty, decree, judgment, and
          </p>
          <p>
            (b) any official directive, protocol, code, guideline, notice,
            approval, order, policy, or other requirement of any Governmental
            Authority having the force of law.
          </p>
          <p>
            "Personally, Identifiable Information" is defined in section
            [INFORMATION COLLECTION].
          </p>
          <p>"User" is defined in the introduction to this policy.</p>
          <p>"Website" is defined in the introduction to this policy.</p>
        </li>
      </ol>
    </div>
  </div>
</template>

<script>
export default {
  name: "Privacy",
};
</script>

<style>
.main_list {
  margin-left: -0.5in;
}

@media only screen and (max-width: 600px) {
  .main_list {
    margin-left: -0.2in;
  }
}
@media only screen and (max-width: 900px) {
  .main_list {
    margin-left: -0.1in;
  }
}
.privacy-container ol.decimal_type {
  counter-reset: item;
}
.privacy-container .decimal_type > li {
  display: block;
}
.privacy-container .decimal_type > li:before {
  content: counters(item, ".") ". ";
  counter-increment: item;
}
</style>
